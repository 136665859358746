@import './styles/form';
@import './styles/theme-colors';

html,
body {
  margin: 0;
  padding: 0;
  scrollbar-color: #e2e8f0 #fffdfd;
  scrollbar-width: thin;
}

// general form component styling that applies to all form components
.form-container {
  display: flex;
  flex-direction: column;
  height: 85vh;
  overflow: hidden;

  .form-content {
    flex: 1;
    overflow-y: auto;
    scrollbar-color: $BlueGray-200 #fffdfd;
    scrollbar-width: thin;

    .form-info {
      padding: 20px 25px;
    }

    .form-inputs {
      padding: 0 25px 20px 25px;

      .form-input {
        margin-bottom: 25px;
        //display: inline-block;
        display: inline-table;
        width: 50%;

        label {
          display: block;
          margin-bottom: 10px;
        }
      }

      .form-input label,
      .form-input input,
      .form-input p-calendar,
      .form-input p-dropdown,
      .form-input p-inputgroup {
        display: block;
        width: 95%;
      }

      input {
        border-color: $BlueGray-300;

        &:focus {
          box-shadow: 0 0 0 0.1rem $BlueGray-300;
        }

        &:hover {
          box-shadow: 0 0 0 0.1rem $BlueGray-300;
        }

        //&:hover {
        //  border-color: red;
        //}
      }

      p-calendar {
        .p-calendar {
          width: 100%;
        }
      }

      //.p-calendar input,
      //.p-dropdown input,
      //.p-dropdown .p-dropdown-label {
      //  width: 100%;
      //  box-sizing: border-box;
      //}

      .section {
        margin-top: 20px;
        padding-bottom: 10px;
        width: 100%;
        border-bottom: 1px solid $BlueGray-200;

        &:last-child {
          border-bottom: none;
        }

        .section-title {
          font-size: 18px;
          font-weight: 600;
          color: $BlueGray-800;
        }

        .input-label {
          color: $accent;
        }
      }
    }
  }

  .form-footer {
    padding: 20px;
    position: sticky;
    bottom: 0;
    color: white;
    text-align: right;

    .form-actions {
      width: 150px;
      border-radius: 4px;
      padding: 8px 12px;
      border: none;
    }

    .form-cancel {
      background-color: #e9f8e8;
      color: #16a34a;
    }

    .form-confirm {
      background-color: #16a34a;
      color: #fff;
      margin-left: 10px;
    }
  }
}

.form-input .p-inputtext {
  //&:focus {
  //  box-shadow: 0 0 0 0.1rem $BlueGray-300;
  //}
  //
  //&:hover {
  //  box-shadow: 0 0 0 0.1rem $BlueGray-300;
  //}

  &:disabled {
    background-color: $BlueGray-200;
    color: $BlueGray-500;
  }
}

p-dropdown {
  .p-dropdown {
    width: 100%;
    border-color: $BlueGray-300 !important;

    &:focus {
      box-shadow: 0 0 0 0.1rem $BlueGray-300 !important;
    }

    &:hover {
      box-shadow: 0 0 0 0.1rem $BlueGray-300 !important;
    }
  }
}

.p-selectbutton .p-button.p-highlight {
  background-color: #e9f8e8;
  border-color: #16a34a;
  color: #16a34a;

  &:focus {
    box-shadow: 0 0 0 0.1rem rgba(18, 175, 240, 0.2);
  }
}

.p-calendar button {
  background: $BlueGray-200;
  border: $BlueGray-200;
}

.p-calendar .p-inputtext {
  border-color: $BlueGray-300;
  //border-right: none;

  &:focus {
    box-shadow: 0 0 0 0.1rem $BlueGray-300;
  }
}

.pointer {
  cursor: pointer;
}

.p-confirm-dialog-message {
  margin-left: 0;
}

.acceptButtonStyleClass {
  background: $primary;
  color: #fff;
  border: none;

  &:focus {
    box-shadow: none;
  }
}

.rejectButtonStyleClass {
  background: #fff;
  color: $primary;
  border: none;
}

.red-font-color {
  color: red;
}

.p-checkbox-box {
  &:hover {
    border-color: #16a34a !important;
  }
}

.p-checkbox .p-checkbox-box.p-highlight {
  border-color: #16a34a !important;
  background: #e9f8e8 !important;
}

.p-checkbox .p-checkbox-box .p-checkbox-icon {
  color: #16a34a !important;
}

.p-button:focus {
  box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #16a34a, 0 1px 2px 0 black;
}

.export-button {
  background-color: #16a34a;
  border-color: #16a34a;
  color: #fff;

  i {
    margin-right: 10px;
  }
}

// table styling
.table-styling {
  .table-container {
    padding: 1px;
    border-radius: 10px;

    .header-item {
      background-color: #f1f5f7;
    }

    .col-header-width {
      min-width: 210px;
    }

    .select-customer-hint {
      text-align: center;
      padding: 30px;
    }

    .no-data-hint {
      text-align: center;
      padding: 30px;
    }
  }

  .wasl-status {
    padding: 6px 9px;
    font-size: 14px;
    border-radius: 4px;
  }

  .status-connected {
    background: #e9f8e8;
    color: #16a34a;
  }

  .status-notConnected {
    background: #fee2e2;
    color: #ef4444;
  }

  .custom-multiselect-label-container {
    cursor: pointer;
  }

  .p-datatable-wrapper {
    @media only screen and (max-width: 1299px) {
      height: 50vh;
    }

    @media only screen and (min-width: 1300px) and (max-width: 1600px) {
      height: 68vh;
    }

    @media only screen and (min-width: 1601px) {
      height: 74vh;
    }
  }

  .p-multiselect-label {
    padding: 0;
  }

  #columns-list .p-inputwrapper {
    border: none;
    background: transparent;
  }

  .p-multiselect-trigger {
    display: none;
  }

  p-paginator {
    position: fixed;
    bottom: 0;
    width: 100%;
  }

  .p-paginator {
    padding-right: 7%;
    box-shadow: 0px -4px 50px 0px #00000012;
    background-color: #ffffff;
    width: 100%;

    .p-paginator-pages .p-paginator-page.p-highlight {
      background: #e9f8e8;
      border-color: #eff6ff;
      color: #16a34a;
    }

    .p-paginator-current {
      position: relative;
      margin-right: 17%;
      right: 11%;
    }
  }
}

.drag-item {
  display: flex;
  align-items: center;
  width: 200px;
  justify-content: space-between;
}

sortalticon {
  svg {
    width: 13px;
    float: right;
  }
}

.multiselect-footer {
  display: flex;
  justify-content: center;
  align-items: center;

  .btn-default {
    border-radius: 4px;
    height: 40px;
    padding: 5px 15px;
    color: #fff;
    margin: 10px;
    cursor: pointer;
    border: 1px solid #16a34a;
    background-color: #16a34a;
  }
}

.multiselect-footer {
  display: flex !important;
  align-items: center !important;
  //gap: 10px !important;
}

.spinner-wrapper {
  width: 20px !important;
  height: 20px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.spinner-wrapper.hidden {
  visibility: hidden !important;
}

.ui-fileupload-buttonbar,
.ui-fileupload-choose {
  display: none;
}
// end table styling

.customers-list {
  display: inline-block;
  float: right;
  margin-right: 20px;

  .p-dropdown {
    width: 240px;
  }
}
